import Hero from "../components/hero";
import * as React from "react";
import Layout from "../components/layout";
import {Container, Typography} from "@mui/material";

const DomainVerificationPage = () => {
    return (
        <Layout title={"Domain Verification"}>
            <main>
                <Hero title={"Domain verification"}
                      subtitle={""}/>
                <Container style={{paddingTop: "3em"}}>
                    <Typography component="div">
                        <table style={{width: "100%", borderCollapse: "collapse"}}>
                            <tbody>
                            <tr>
                                <td style={{padding: "8px", border: "1px solid #ddd"}}><strong>Company:</strong></td>
                                <td style={{padding: "8px", border: "1px solid #ddd"}}>Jurybox Technologies LLC</td>
                            </tr>
                            <tr>
                                <td style={{padding: "8px", border: "1px solid #ddd"}}><strong>Owner:</strong></td>
                                <td style={{padding: "8px", border: "1px solid #ddd"}}>James K. Fry</td>
                            </tr>
                            <tr>
                                <td style={{padding: "8px", border: "1px solid #ddd"}}><strong>Address:</strong>
                                </td>
                                <td style={{padding: "8px", border: "1px solid #ddd"}}>
                                    13601 W McMillan Rd<br/>
                                    Ste 102-254<br/>
                                    Boise, ID 83713
                                </td>
                            </tr>
                            <tr>
                                <td style={{padding: "8px", border: "1px solid #ddd"}}><strong>Email:</strong></td>
                                <td style={{padding: "8px", border: "1px solid #ddd"}}>jfry@juryboxapp.com</td>
                            </tr>
                            <tr>
                                <td style={{padding: "8px", border: "1px solid #ddd"}}><strong>Phone:</strong></td>
                                <td style={{padding: "8px", border: "1px solid #ddd"}}>(208) 600-1231</td>
                            </tr>
                            </tbody>
                        </table>
                    </Typography>
                </Container>
            </main>
        </Layout>
    );
};

export default DomainVerificationPage;